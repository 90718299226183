import { defineStore } from "pinia";

export const authStore = defineStore('auth', {
  state: () => ({
    isAuthenticated: false,
    username: '',
  }),
  actions: {
    async check() {
      const res = await fetch('/api/auth/check');
      if (res.status !== 200) return false;

      const { username } = await res.json();
      this.username = username;
      this.isAuthenticated = true;
      return true;
    },
    async signin(username, password) {
      const res = await fetch('/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username,
          password,
        })
      });
      if (res.status !== 200) {
        return false;
      }
      this.username = username;
      this.isAuthenticated = true;
      return true;
    },
    async signout() {
      const res = await fetch('/api/auth/logout', {
        method: 'POST'
      });
      if (res.status !== 200) return false;

      this.username = '';
      this.isAuthenticated = false;
      return true;
    },
    async recover(/* code */) {
      this.username = 'Anders Johansson';
      this.isAuthenticated = true;
      localStorage.setItem('username', this.username);
    }
  }
});
